<template>
    <div class="container">
        <div class="text_div">
            <h3 class="text_404 mb40">페이지를 찾을 수 없습니다.</h3>
            <div class="links text-center">
                <router-link :to="{ name: pathName }" class="btn solid">
                    메인으로
                </router-link>
            </div>
        </div>
        
         <div class="image-container">
            <img class="mainLogo" src="../../assets/images/logo.svg">
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFound',
    data() {
        return {
            pathName: null
        };
    },
    mounted() {
        this.setPathName();
    },
    methods: {
        setPathName(){
            const role = this.$store.getters['auth/userRole'];
            if(role){
                if(role === 'A') this.pathName = 'userManage'
                else this.pathName = 'step1'
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    padding: 70px 50px;
}
.text_div{
    margin-top: 120px;
}
.text_404 {
    color: #fff;
    font-size: 28px;
}
.image-container {
    margin-bottom: 20px;
}

</style>
